<script setup>
import { computed, watch, defineProps, ref } from 'vue'
import AnalyticsCardWrapper from '@src/modules/analytics_v3/components/AnalyticsCardWrapper.vue'
import MainGraph from '@src/modules/analytics/views/instagram_v2/components/graphs/MainGraph.vue'
import SkeletonBox from '@src/modules/analytics/views/common/SkeletonBox.vue'
import useInstagramAnalytics from '@src/modules/analytics/views/instagram_v2/composables/useInstagramAnalytics.js'
import CstDropdown from '@ui/Dropdown/CstDropdown.vue'
import CstDropdownItem from '@ui/Dropdown/IconDropdownItem.vue'
import ChevronDownIcon from '@/src/assets/img/chevron-down.svg'

// state
const {
  overviewStoryInsightsData,
  dataZoomOptions,
  isReportView,
  isLoadingStates,
  routes,
  analyticsDesignSystem,
  legendOptions,

  generateStackedChartOptions,
} = useInstagramAnalytics('Story Performance')

const props = defineProps({
  type: {
    type: String,
    default: 'interactions',
  },
})

const optionsDropdown = [
  {
    label: 'Stories Performance by Interactions',
    key: 'interactions',
    chartType: 'bar',
    tooltip: `See how users interact with your Stories through replies, taps, and exits in the selected time period.`,
  },
  {
    label: 'Stories Performance by Impressions',
    key: 'impressions',
    chartType: 'bar',
    tooltip: `Track the total number of times your Stories were viewed in the selected time period.`,
  },
  {
    label: 'Stories Performance by Reach',
    key: 'reach',
    chartType: 'bar',
    tooltip: `Discover how many unique users viewed your Stories in the selected time period.`,
  },
]

const interactionsChartMap = [
  {
    name: 'Taps Forward',
    stack: 'Interactions',
    color: '#8AC1A2',
    position: 'left',
    api_response_key: 'story_taps_forward',
    yIndex: 0,
  },
  {
    name: 'Taps Back',
    stack: 'Interactions',
    color: '#8081B9',
    position: 'left',
    api_response_key: 'story_taps_back',
    yIndex: 0,
  },
  {
    name: 'Exits',
    stack: 'Interactions',
    color: '#F2666B',
    position: 'left',
    api_response_key: 'story_exits',
    yIndex: 0,
  },
  {
    name: 'Replies',
    stack: 'Interactions',
    color: '#F2CA6B',
    position: 'left',
    api_response_key: 'story_reply',
    yIndex: 0,
  },
  {
    name: 'Total Stories',
    stack: 'Stories',
    color: '#8ABAF3',
    position: 'right',
    api_response_key: 'published_stories',
    yIndex: 1,
  },
]

const impressionsChartMap = [
  {
    name: 'Impressions',
    stack: 'Impressions',
    color: '#8AC1A2',
    position: 'left',
    api_response_key: 'story_impressions',
    yIndex: 0,
  },
  {
    name: 'Total Stories',
    stack: 'Stories',
    color: '#8ABAF3',
    position: 'right',
    api_response_key: 'published_stories',
    yIndex: 1,
  },
]

const reachChartMap = [
  {
    name: 'Reach',
    stack: 'Reach',
    color: '#8AC1A2',
    position: 'left',
    api_response_key: 'story_reach',
    yIndex: 0,
  },
  {
    name: 'Total Stories',
    stack: 'Stories',
    color: '#8ABAF3',
    position: 'right',
    api_response_key: 'published_stories',
    yIndex: 1,
  },
]

const selectedOptionType = ref(
  optionsDropdown.find((i) => i.key === 'interactions')
)

// Dynamic chart configuration generation
const generateSeries = (charts, options) => {
  return charts.map((chart) => ({
    name: chart.name,
    type: 'bar',
    stack: chart?.stack || undefined,
    data: options[chart.api_response_key] || [],
    color: chart.color,
    colorBy: 'series',
    barMaxWidth: analyticsDesignSystem?.graphs?.dualBarMaxWidth,
    yAxisIndex: chart?.yIndex,
    areaStyle: { opacity: 0.4, cursor: 'auto' },
    cursor: 'auto',
  }))
}

const generateLegend = (charts) => ({
  ...legendOptions,
  data: charts
    .map((chartType) =>
      !IGNORE_LEGENDS.includes(chartType.name) ? chartType.name : null
    )
    .filter(Boolean),
})

const updateChartOptions = (chartOptions, charts, options) => {
  chartOptions.value.series = generateSeries(charts, options)
  chartOptions.value.legend = generateLegend(charts)
  chartOptions.value.xAxis.data = options?.buckets || []
}

const interactionsChartOptions = ref(
  generateStackedChartOptions(interactionsChartMap)
)
const impressionsChartOptions = ref(
  generateStackedChartOptions(impressionsChartMap)
)
const reachChartOptions = ref(generateStackedChartOptions(reachChartMap))

const getSelectedType = computed(() => {
  return isReportView.value
    ? optionsDropdown.find((i) => i.key === props.type)?.label || ''
    : selectedOptionType.value.label
})

const isNoData = computed(() => {
  return (
    overviewStoryInsightsData.value?.stories_performance?.buckets?.length === 0
  )
})
const getSelectedChartOptions = computed(() => {
  const option = isReportView.value ? props.type : selectedOptionType.value.key
  switch (option) {
    case 'interactions':
      return interactionsChartOptions.value
    case 'impressions':
      return impressionsChartOptions.value
    case 'reach':
      return reachChartOptions.value
    default:
      return interactionsChartOptions.value
  }
})

const IGNORE_LEGENDS = ['Total Stories']

watch(
  () => overviewStoryInsightsData.value,
  () => {
    const options = overviewStoryInsightsData.value?.stories_performance || {}
    updateChartOptions(interactionsChartOptions, interactionsChartMap, options)
    updateChartOptions(impressionsChartOptions, impressionsChartMap, options)
    updateChartOptions(reachChartOptions, reachChartMap, options)
  },
  {
    deep: true,
    immediate: true,
  }
)

const isLoading = computed(
  () => isLoadingStates.value?.[routes.OVERVIEW_STORY_ANALYTICS]
)
</script>

<template>
  <AnalyticsCardWrapper type="story-performance-insta" :enable-modal="true">
    <template v-slot:card-header>
      <div v-if="!isReportView" class="w-full flex justify-between items-center">
        <div class="flex items-center gap-2">
          <div class="flex align-items-center gap-2">
            <CstDropdown
              dropdown-placement="bottom"
              container-classes="!max-h-96"
              dropdown-classes="!rounded-md !border-[#70707029]"
              button-classes="flex !px-5 !w-[22rem] !rounded-md !bg-[#F8F8F880] !border-[#70707029] !text-[#4A4A4A]"
            >
              <template v-slot:arrow>
                <img :src="ChevronDownIcon" alt="chevron-down" />
              </template>
              <template v-slot:selected>
                <p class="text-[14px] font-weight-500 capitalize">
                  {{ getSelectedType }}
                </p>
              </template>
              <template v-slot>
                <template
                  v-for="(item, index) in optionsDropdown"
                  :key="`activity_type_${index}`"
                >
                  <CstDropdownItem @click="selectedOptionType = item">
                    <p class="text-gray-900 text-sm">{{ item.label }}</p>
                  </CstDropdownItem>
                </template>
              </template>
            </CstDropdown>
            <v-menu
              v-if="!isReportView"
              :popper-triggers="['hover']"
              placement="top"
              popper-class="first-comment__info-popover"
              :delay="300"
            >
              <i class="far fa-question-circle p-0 cursor-pointer"></i>
              <template v-slot:popper>
                <p class="text-sm text-gray-900">
                  {{ selectedOptionType.tooltip }}
                </p>
              </template>
            </v-menu>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:card-body="slotProps">
      <div class="flex-1">
        <SkeletonBox
          v-if="isLoading"
          width="100%"
          height="350px"
          radius="8px"
        />
        <MainGraph
          v-else-if="!isNoData"
          :key="Math.random()"
          type="bar"
          :chart-options="
            slotProps.isModal
              ? {
                  ...getSelectedChartOptions,
                  ...dataZoomOptions,
                  grid: { ...getSelectedChartOptions.grid, bottom: 70 },
                }
              : getSelectedChartOptions
          "
          :is-modal="slotProps.isModal"
        />
        <div v-else class="analytics-no-data-found">
          <img
            src="@src/assets/img/no_data_images/no-analytical-data-available.svg"
            alt=""
          />
          <p>No data found.</p>
        </div>
      </div>
    </template>
  </AnalyticsCardWrapper>
</template>
