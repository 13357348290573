<script setup>
import { watch } from 'vue'
import useInstagramAnalytics from '@src/modules/analytics/views/instagram_v2/composables/useInstagramAnalytics.js'
import AnalyticsCardWrapper from '@src/modules/analytics_v3/components/AnalyticsCardWrapper.vue'
import SkeletonBox from '@/src/modules/analytics/views/common/SkeletonBox.vue'
import TopPostCard from '@/src/modules/analytics/views/instagram_v2/components/TopPostCard.vue'
import EngagementIcon from "@/src/assets/img/icons/analytic/plain/total-engagement.svg";
import HeartIcon from "@/src/assets/img/icons/analytic/plain/heart.svg";
import CommentsIcon from "@/src/assets/img/icons/analytic/plain/comments-icon.svg";
import SavedIcon from "@/src/assets/img/icons/analytic/plain/saved-icon.svg";
import ImpressionsIcon from "@/src/assets/img/icons/analytic/plain/post-impressions.svg";
import ReachPlainIcon from "@/src/assets/img/icons/analytic/plain/reach.svg";
import PostTypeIcon from "@/src/assets/img/icons/analytic/plain/post-type.svg";

const { routes, selectedAccount , overviewTopPostsData, isReportView, isLoadingStates, mediaTypeMap } = useInstagramAnalytics()

const rows = [
  {
    icon: EngagementIcon,
    title: 'Engagement',
    api_key: 'engagement',
    iconClass: 'scale-[1.2]'
  },
  {
    icon: ImpressionsIcon,
    title: 'Impressions',
    api_key: 'impressions',
    iconClass: ''
  },
  {
    icon: ReachPlainIcon,
    title: 'Reach',
    api_key: 'reach',
    iconClass: ''
  },
  {
    icon: HeartIcon,
    title: 'Likes',
    api_key: 'like_count',
    iconClass: ''
  },
  {
    icon: CommentsIcon,
    title: 'Comments',
    api_key: 'comments_count',
    iconClass: ''
  },
  {
    icon: SavedIcon,
    title: 'Saved',
    api_key: 'saved',
    iconClass: ''
  },

  {
    icon: PostTypeIcon,
    title: 'Post Type',
    api_key: 'media_type',
    iconClass: ''
  },
]

const getMediaType = (mediaType) => mediaTypeMap[mediaType] || mediaType

watch(() => overviewTopPostsData.value, () => {
  if (overviewTopPostsData.value && overviewTopPostsData.value.length) {
    overviewTopPostsData.value.forEach((post) => {
      post.media_type = getMediaType(post?.media_type)
    })
  }
},{
  immediate: true,
  deep: true
})

</script>

<template>
   <AnalyticsCardWrapper type="fb-top-posts">
    <template v-slot:card-header>
      <div v-if="!isReportView" class="w-full flex justify-between items-center">
        <div class="flex items-center gap-2">
          <div class="flex align-items-center gap-2">
            <h2 class="text-base font-weight-500 select-none">
              Top Posts
            </h2>
            <v-menu
              v-if="!isReportView"
              :popper-triggers="['hover']"
              placement="top"
              popper-class="first-comment__info-popover"
              :delay="300"
            >
              <i class="far fa-question-circle p-0 cursor-pointer"></i>
              <template v-slot:popper>
                <p class="text-sm text-gray-900">
                  The top performing posts of the page by Engagement, published during the selected time period.
                </p>
              </template>
            </v-menu>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:card-body="">
      <template v-if="!isLoadingStates[routes?.OVERVIEW_TOP_POSTS] && overviewTopPostsData?.length === 0">
        <div class="analytics-no-data-found">
          <img
            src="@src/assets/img/no_data_images/no-analytical-data-available.svg"
            alt=""
          />
          <p>No data found.</p>
        </div>
      </template>
      <template v-else>
        <div
          class="grid  gap-5 "
          :class="{ 'grid-cols-3': isReportView, 'grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 h-[535px] overflow-hidden px-8': !isReportView }"
        >
          <template
            v-if="isLoadingStates[routes?.OVERVIEW_TOP_POSTS]"
          >
            <template v-for="i in [1,2,3,4,5]" :key="i">
                <SkeletonBox
                :height="'500px'"
                width="'100%'"
                />
            </template>
          </template>
          <template v-else>
            <template v-for="(post,i) in overviewTopPostsData" :key="i">
              <TopPostCard :selected-account="selectedAccount" :post="post" :metrics-rows="rows" />
            </template>
          </template>
        </div>
      </template>
    </template>
  </AnalyticsCardWrapper>
</template>
