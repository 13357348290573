<script setup>
import { computed, watch, defineProps, ref } from 'vue'
import AnalyticsCardWrapper from '@src/modules/analytics_v3/components/AnalyticsCardWrapper.vue'
import MainGraph from '@src/modules/analytics/views/facebook_v2/components/graphs/MainGraph.vue'
import SkeletonBox from '@src/modules/analytics/views/common/SkeletonBox.vue'
import useFacebookAnalytics from '@src/modules/analytics/views/facebook_v2/composables/useFacebookAnalytics.js'
import CstDropdown from '@ui/Dropdown/CstDropdown.vue'
import CstDropdownItem from '@ui/Dropdown/IconDropdownItem.vue'
import ChevronDownIcon from '@/src/assets/img/chevron-down.svg'

// state
const {
  analyticsDesignSystem,
  audienceGrowthData,
  dataZoomOptions,
  lineChartOptions: cumulativelineChartOptions,
  barChartOptions: dailyChartOptions,
  isReportView,
  isLoadingStates,
  routes,

  axisLabelFormatter,
} = useFacebookAnalytics('Audience Growth')

const props = defineProps({
  type: {
    type: String,
    default: 'cumulative',
  },
})

const optionsDropdown = [
  {
    label: 'Cumulative Audience Growth',
    key: 'cumulative',
    chartType: 'line',
    tooltip:
      'Shows the cumulative growth in your audience over time, reflecting the total number of new followers gained and retained across the selected period.',
  },
  {
    label: 'Daily Audience Growth',
    key: 'daily',
    chartType: 'bar',
    tooltip:
      'Tracks the daily change in audience size by showing the number of new followers gained and lost over time. Positive values indicate growth, while negative values reflect a decline. Data is displayed for the selected date range.',
  },
]

const selectedOptionType = ref(
  optionsDropdown.find((i) => i.key === props.type) || optionsDropdown[0]
)

const isNoData = computed(() => {
  return audienceGrowthData.value?.audience_growth?.buckets?.length === 0
})

const getSelectedType = computed(() => {
  return selectedOptionType.value.label
})

const selectedChartOptions = computed(() => {
  return selectedOptionType.value.key === 'daily'
    ? dailyChartOptions.value
    : cumulativelineChartOptions.value
})

watch(
  () => audienceGrowthData.value,
  () => {
    const options = audienceGrowthData.value?.audience_growth || {}
    const daily = options?.page_fans_daily || []
    const cumulative = options?.fan_count || []

    cumulativelineChartOptions.value.series[0].data = cumulative || []
    cumulativelineChartOptions.value.xAxis.data = options?.buckets
    cumulativelineChartOptions.value.yAxis.nameGap = 50

    dailyChartOptions.value.series[0].data = daily || []
    dailyChartOptions.value.series[0].itemStyle.borderRadius = undefined
    dailyChartOptions.value.xAxis.data = options?.buckets

    dailyChartOptions.value.yAxis.axisLabel.formatter = axisLabelFormatter
    dailyChartOptions.value.yAxis.axisLabel.rich =
      analyticsDesignSystem?.graphs?.richStylesBySentiment
    dailyChartOptions.value.yAxis.nameGap = 60
  },
  {
    deep: true,
    immediate: true,
  }
)
const isLoading = computed(
  () => isLoadingStates.value?.[routes.OVERVIEW_AUDIENCE_GROWTH]
)
</script>

<template>
  <AnalyticsCardWrapper
    type="audience-growth-fb"
    :enable-modal="true"
    :show-insights-button="!isReportView"
  >
    <template v-slot:card-header>
      <div v-if="!isReportView" class="w-full flex justify-between items-center">
        <div class="flex items-center gap-2">
          <div class="flex align-items-center gap-2">
            <CstDropdown
              dropdown-placement="bottom"
              container-classes="!max-h-96"
              dropdown-classes="!rounded-md !border-[#70707029]"
              button-classes="flex !px-5 !w-[17rem] !rounded-md !bg-[#F8F8F880] !border-[#70707029] !text-[#4A4A4A]"
            >
              <template v-slot:arrow>
                <img :src="ChevronDownIcon" alt="chevron-down" />
              </template>
              <template v-slot:selected>
                <p class="text-[14px] font-weight-500 capitalize">
                  {{ getSelectedType }}
                </p>
              </template>
              <template v-slot>
                <template
                  v-for="(item, index) in optionsDropdown"
                  :key="`activity_type_${index}`"
                >
                  <CstDropdownItem @click="selectedOptionType = item">
                    <p class="text-gray-900 text-sm">{{ item.label }}</p>
                  </CstDropdownItem>
                </template>
              </template>
            </CstDropdown>
            <v-menu
              v-if="!isReportView"
              :popper-triggers="['hover']"
              placement="top"
              popper-class="first-comment__info-popover"
              :delay="300"
            >
              <i class="far fa-question-circle p-0 cursor-pointer"></i>
              <template v-slot:popper>
                <p class="text-sm text-gray-900">
                  {{ selectedOptionType.tooltip }}
                </p>
              </template>
            </v-menu>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:card-body="slotProps">
      <div class="flex-1">
        <SkeletonBox
          v-if="isLoading"
          width="100%"
          height="fit-content"
          radius="8px"
        />
        <MainGraph
          v-else-if="!isNoData"
          :key="Math.random()"
          :type="selectedOptionType.chartType"
          :custom-modal-height = "slotProps.isInsights ? '40vh' : '80vh' "
          :chart-options="
            slotProps.isModal
              ? {
                  ...selectedChartOptions,
                  ...dataZoomOptions,
                  grid: { ...selectedChartOptions.grid, bottom: 70 },
                }
              : selectedChartOptions
          "
          :is-modal="slotProps.isModal"
        />
        <div v-else class="analytics-no-data-found">
          <img
            src="@src/assets/img/no_data_images/no-analytical-data-available.svg"
            alt=""
          />
          <p>No data found.</p>
        </div>
      </div>
    </template>
  </AnalyticsCardWrapper>
</template>
